import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getButtonSize, getButtonAngle } from '../state/selectors';
import { clickButton } from '../state/slice';
import { IButton, State } from '../state/types';

export default memo(function Button({ button }: { button: IButton }) {
    const dispatch = useDispatch();
    const onClick = () => dispatch(clickButton(button.id));

    const size = useSelector((state: State) => getButtonSize(state, button.id));
    const angle = useSelector((state: State) => getButtonAngle(state, button.id));

    const style: React.CSSProperties = {
        position: 'absolute',
        left: `${button.position.x}px`,
        top: `${button.position.y}px`,
        width: `${size.width}px`,
        height: `${size.height}px`,
        transform: `rotate(${angle}deg)`,
    };

    return (
        <button style={style} onClick={onClick}>
            {button.id}
        </button>
    );
});
