import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getButtonSize, getSizeFromButton, getLevelFromButton } from './selectors';
import { State, IButton } from './types';
import Victor = require('victor');
import { Polygon, Vector, testPolygonPolygon } from 'sat';

const INITIAL_BUTTON_ID = 0;
let nextId = INITIAL_BUTTON_ID;
const getNextId = () => nextId++;

const getCurrentTime = () => new Date().getTime();

function makeNewButton(info: Omit<IButton, 'id' | 'creationTime'>): IButton {
    return {
        ...info,
        id: getNextId(),
        creationTime: getCurrentTime(),
    };
}

const initialState: State = {
    containerSize: {
        width: 0,
        height: 0,
    },
    lastTime: null,
    buttons: {
        [INITIAL_BUTTON_ID]: makeNewButton({
            level: 1,
            position: { x: 100, y: 100 },
            direction: { x: -1, y: 0 },
            speed: 0,
        }),
    },
};

function getSum(numbers: number[]): number {
    let result = 0;
    for (const number of numbers) {
        result += number;
    }
    return result;
}

function getAverage(numbers: number[]): number {
    if (numbers.length === 0) {
        throw new Error('Cannot average 0 values');
    }
    return getSum(numbers) / numbers.length;
}

function getVelocity(button: IButton): Victor {
    return new Victor(button.direction.x, button.direction.y).multiplyScalar(button.speed);
}

/** Amount to reduce speed by (pixels per second per second) */
const DECELERATION = 100;

const slice = createSlice({
    name: 'buttons',
    initialState,
    reducers: {
        containerResized: {
            prepare() {
                return {
                    payload: {
                        width: document.body.offsetWidth,
                        height: document.body.offsetHeight,
                    },
                };
            },
            reducer(state: State, { payload: containerSize }: PayloadAction<State['containerSize']>) {
                state.containerSize = containerSize;

                const initialButton = state.buttons[INITIAL_BUTTON_ID];
                if (initialButton != null) {
                    const buttonSize = getButtonSize(state, INITIAL_BUTTON_ID);
                    initialButton.position = {
                        x: (containerSize.width - buttonSize.width) / 2,
                        y: (containerSize.height - buttonSize.height) / 2,
                    };
                }
            },
        },
        updatePhysics(state, { payload: time }: PayloadAction<number>) {
            const lastTime = state.lastTime ?? time;
            state.lastTime = time;

            const elapsedMs = time - lastTime;
            const elapsedSeconds = elapsedMs / 1000;

            // Movement

            const amountToReduceSpeed = elapsedSeconds * DECELERATION;

            for (const button of Object.values(state.buttons)) {
                button.speed = Math.max(0, button.speed - amountToReduceSpeed);
                if (button.speed === 0) {
                    continue;
                }

                const direction = new Victor(button.direction.x, button.direction.y).norm();

                const { x: left, y: top } = button.position;
                const { width, height } = getSizeFromButton(button);
                const right = left + width;
                const bottom = top + height;

                // Collision with container boundaries
                if ((left < 0 && direction.x < 0) || (right > state.containerSize.width && direction.x > 0)) {
                    direction.invertX();
                }
                if ((top < 0 && direction.y < 0) || (bottom > state.containerSize.height && direction.y > 0)) {
                    direction.invertY();
                }

                button.direction = { x: direction.x, y: direction.y };

                const posChange = direction.multiplyScalar(button.speed * elapsedSeconds);
                button.position.x += posChange.x;
                button.position.y += posChange.y;
            }

            // Collision with other buttons

            const now = getCurrentTime();
            const pairs = Object.values(state.buttons)
                // Prevent collision during the first moments of a new button's existence
                .filter((button) => now - button.creationTime > 200)
                .map((button) => {
                    const { width, height } = getSizeFromButton(button);
                    const halfWidth = width / 2;
                    const halfHeight = height / 2;
                    const polygon = new Polygon(new Vector(0, 0), [
                        new Vector(-halfWidth, -halfHeight),
                        new Vector(halfWidth, -halfHeight),
                        new Vector(halfWidth, halfHeight),
                        new Vector(-halfWidth, halfHeight),
                    ]);
                    // Invert `y` because the `SAT` library expects (0, 0) to be at the bottom-left
                    polygon.rotate(new Victor(button.direction.x, -button.direction.y).angle());
                    polygon.translate(button.position.x, button.position.y);
                    return { button, polygon };
                });

            const collidingGroups: IButton[][] = [];
            while (pairs.length > 0) {
                const { button, polygon } = pairs.shift()!;
                const collidingButtons = pairs
                    .map((pair, i) => ({ ...pair, i }))
                    .filter(({ polygon: otherPolygon }) => testPolygonPolygon(polygon, otherPolygon));

                if (collidingButtons.length > 0) {
                    // Sort in reverse index order (to avoid changing other indices), then remove from `pairs`
                    collidingButtons.sort((a, b) => b.i - a.i).forEach(({ i }) => pairs.splice(i, 1));
                    const group = collidingButtons.map((pair) => pair.button);
                    group.push(button);
                    collidingGroups.push(group);
                }
            }

            for (const group of collidingGroups) {
                const level = getSum(group.map(getLevelFromButton));
                const velocity = group.map(getVelocity).reduce((acc, val) => acc.add(val));
                const speed = velocity.magnitude();
                const direction = velocity.norm();
                const x = getAverage(group.map((button) => button.position.x));
                const y = getAverage(group.map((button) => button.position.y));
                const combinedButton = makeNewButton({
                    level,
                    position: { x, y },
                    direction: { x: direction.x, y: direction.y },
                    speed,
                });
                state.buttons[combinedButton.id] = combinedButton;
                for (const button of group) {
                    delete state.buttons[button.id];
                }
                console.groupEnd();
            }
        },
        clickButton(state, { payload: buttonId }: PayloadAction<number>) {
            const { position } = state.buttons[buttonId];
            delete state.buttons[buttonId];

            for (let i = 0; i < 15; i++) {
                const vector = new Victor(-1, 0).rotateByDeg(random(0, 359));
                const button = makeNewButton({
                    level: 1,
                    position,
                    direction: { x: vector.x, y: vector.y },
                    speed: random(500, 1000),
                });
                state.buttons[button.id] = button;
            }
        },
    },
});

export const { containerResized, updatePhysics, clickButton } = slice.actions;
export default slice.reducer;

function random(low: number, high: number): number {
    return Math.floor(Math.random() * (high - low + 1)) + low;
}
