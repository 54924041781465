import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getButtons } from '../state/selectors';
import Button from './Button';

export default memo(function ButtonContainer() {
    const buttons = useSelector(getButtons);
    return (
        <>
            {Object.values(buttons).map(button => (
                <Button key={button.id} button={button} />
            ))}
        </>
    );
});
