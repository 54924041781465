import { createSelector } from '@reduxjs/toolkit';
import { IButton, Size, State } from './types';
import Victor = require('victor');

// Simple selectors

export const getButtons = (state: State) => state.buttons;
export const getButton = (state: State, id: IButton['id']): IButton => {
    const button = state.buttons[id];
    if (button == null) {
        throw new Error('Attempted to get non-existent button with ID ' + id);
    }
    return button;
};

// Non-state selectors

export const getLevelFromButton = (button: IButton) => button.level;

export const getSizeFromLevel = (level: number): Size => {
    const levelAdjustment = 1 + 0.2 * (level - 1);
    return {
        width: 60 * levelAdjustment,
        height: 25 * levelAdjustment,
    };
};

export const getSizeFromButton = (button: IButton) => getSizeFromLevel(getLevelFromButton(button));

export const getAngleFromButton = (button: IButton) =>
    new Victor(button.direction.x, button.direction.y).angleDeg() + 180;

// Memoized selectors

export const getButtonSize = createSelector(getButton, getSizeFromButton);
export const getButtonAngle = createSelector(getButton, getAngleFromButton);
