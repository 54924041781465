import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ButtonContainer from './components/ButtonContainer';
import { containerResized, updatePhysics } from './state/slice';
import store from './state/store';

document.addEventListener('DOMContentLoaded', () => {
    store.dispatch(containerResized());
    window.addEventListener('resize', () => store.dispatch(containerResized()));

    const root = createRoot(document.getElementById('app')!);
    root.render(
        <Provider store={store}>
            <ButtonContainer />
        </Provider>,
    );

    const refresh = (time: number) => {
        store.dispatch(updatePhysics(time));
        requestAnimationFrame(refresh);
    };
    requestAnimationFrame(refresh);
});
